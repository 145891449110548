import { Injectable } from '@angular/core';

import { DictionaryItem } from '@pinup-teams/common';
import {
  AchievementStatus,
  AchievementType,
  AutomaticCongratsType,
  CourseStatusType,
  DeliveryMethod,
  GroupTagEnum,
  ManualCongratsType,
  MessageStatus,
  Messengers,
  QuestStatusType,
  SlackChannelType,
  SortType,
  SupportRequestCategoryType,
  SupportRequestStatusType,
  TgChatPermissions,
  UserRoles,
  TgChatTypes,
  UserStatusType,
  WorkingMessengerLinks,
  TgUserPermissions,
} from '@pt/models';
import { TranslateService } from '@ngx-translate/core';

import {
  CourseStatus,
} from '../../../../admin/src/lib/pages/lms-analytic/pages/short-report/models';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService {
  deliveries: DeliveryMethod[] = [
    { id: 'international', name: this._translate.instant('dictionary.deliveries.international') },
    { id: 'ukrposhta', name: this._translate.instant('dictionary.deliveries.ukrposhta') },
    { id: 'nova_poshta', name: this._translate.instant('dictionary.deliveries.novaPoshta') },
    { id: 'fedex', name: this._translate.instant('dictionary.deliveries.fedex') },
    { id: 'dhl', name: this._translate.instant('dictionary.deliveries.dhl') },
    { id: 'dpd', name: this._translate.instant('dictionary.deliveries.dpd') },
    { id: 'inpost', name: this._translate.instant('dictionary.deliveries.inpost') },
    { id: 'acs', name: this._translate.instant('dictionary.deliveries.acs') },
    {
      id: 'gap_akis_express',
      name: this._translate.instant('dictionary.deliveries.gapAkisExpress'),
    },
  ];

  orderStatuses: DictionaryItem<string>[] = [
    { id: null, name: this._translate.instant('dictionary.orderStatuses.all') },
    { id: 'new', name: this._translate.instant('dictionary.orderStatuses.new') },
    { id: 'confirmed', name: this._translate.instant('dictionary.orderStatuses.confirmed') },
    { id: 'shipping', name: this._translate.instant('dictionary.orderStatuses.shipping') },
    { id: 'delivered', name: this._translate.instant('dictionary.orderStatuses.delivered') },
    { id: 'completed', name: this._translate.instant('dictionary.orderStatuses.completed') },
    { id: 'canceled', name: this._translate.instant('dictionary.orderStatuses.canceled') },
  ];

  questStatuses: DictionaryItem<QuestStatusType>[] = [
    { id: null, name: this._translate.instant('dictionary.questStatuses.all') },
    { id: 'inProgress', name: this._translate.instant('dictionary.questStatuses.inProgress') },
    { id: 'inReview', name: this._translate.instant('dictionary.questStatuses.inReview') },
    { id: 'completed', name: this._translate.instant('dictionary.questStatuses.completed') },
    { id: 'rejected', name: this._translate.instant('dictionary.questStatuses.rejected') },
    { id: 'canceled', name: this._translate.instant('dictionary.questStatuses.canceled') },
  ];

  supportRequestStatuses: DictionaryItem<SupportRequestStatusType>[] = [
    {
      id: SupportRequestStatusType.New,
      name: this._translate.instant('dictionary.supportRequestStatuses.new'),
    },
    {
      id: SupportRequestStatusType.InProgress,
      name: this._translate.instant('dictionary.supportRequestStatuses.inProgress'),
    },
    {
      id: SupportRequestStatusType.Completed,
      name: this._translate.instant('dictionary.supportRequestStatuses.completed'),
    },
  ];

  courseStatuses: DictionaryItem<CourseStatusType>[] = [
    { id: 'inProgress', name: this._translate.instant('dictionary.courseStatuses.inProgress') },
    { id: 'completed', name: this._translate.instant('dictionary.courseStatuses.completed') },
    { id: 'notPassed', name: this._translate.instant('dictionary.courseStatuses.notPassed') },
  ];

  messageStatuses: DictionaryItem<MessageStatus>[] = [
    {
      id: MessageStatus.Scheduled,
      name: this._translate.instant('dictionary.messageStatuses.scheduled'),
    },
    { id: MessageStatus.Sent, name: this._translate.instant('dictionary.messageStatuses.sent') },
  ];

  periods: DictionaryItem<number>[] = [
    { id: null, name: this._translate.instant('dictionary.periods.all') },
    { id: 1, name: this._translate.instant('dictionary.periods.month') },
    { id: 3, name: this._translate.instant('dictionary.periods.threeMonths') },
    { id: 6, name: this._translate.instant('dictionary.periods.sixMonths') },
    { id: 12, name: this._translate.instant('dictionary.periods.year') },
  ];

  supportRequestCategories: DictionaryItem<SupportRequestCategoryType>[] = [
    {
      id: SupportRequestCategoryType.Office,
      name: this._translate.instant('dictionary.supportCategories.office'),
      code: 'AdminSupportOffice',
    },
    {
      id: SupportRequestCategoryType.Education,
      name: this._translate.instant('dictionary.supportCategories.education'),
      code: 'AdminSupportEducation',
    },
    {
      id: SupportRequestCategoryType.Platform,
      name: this._translate.instant('dictionary.supportCategories.platform'),
      code: 'AdminSupportPlatform',
    },
    {
      id: SupportRequestCategoryType.Finance,
      name: this._translate.instant('dictionary.supportCategories.finance'),
      code: 'AdminSupportFinance',
    },
  ];

  events: DictionaryItem<string>[] = [
    { id: 'new_account', name: this._translate.instant('dictionary.events.newAccount') },
    { id: 'probation_end', name: this._translate.instant('dictionary.events.probationEnd') },
    { id: 'birthday', name: this._translate.instant('dictionary.events.birthday') },
    {
      id: 'personal_year_start',
      name: this._translate.instant('dictionary.events.personalYearStart'),
    },
  ];

  months: DictionaryItem<number>[] = [
    { id: 1, name: this._translate.instant('dictionary.months.january') },
    { id: 2, name: this._translate.instant('dictionary.months.february') },
    { id: 3, name: this._translate.instant('dictionary.months.march') },
    { id: 4, name: this._translate.instant('dictionary.months.april') },
    { id: 5, name: this._translate.instant('dictionary.months.may') },
    { id: 6, name: this._translate.instant('dictionary.months.june') },
    { id: 7, name: this._translate.instant('dictionary.months.july') },
    { id: 8, name: this._translate.instant('dictionary.months.august') },
    { id: 9, name: this._translate.instant('dictionary.months.september') },
    { id: 10, name: this._translate.instant('dictionary.months.october') },
    { id: 11, name: this._translate.instant('dictionary.months.november') },
    { id: 12, name: this._translate.instant('dictionary.months.december') },
  ];

  automaticAccrual: DictionaryItem<string>[] = [
    {
      id: 'monthly-group-tasks',
      name: this._translate.instant('admin.pincoins.automaticAccrual.table.type.monthlyGroup'),
      description: this._translate.instant(
        'admin.pincoins.automaticAccrual.table.description.automaticAccrueMonthly',
      ),
    },
    {
      id: 'yearly-group-tasks',
      name: this._translate.instant('admin.pincoins.automaticAccrual.table.type.yearlyGroup'),
      description: this._translate.instant(
        'admin.pincoins.automaticAccrual.table.description.automaticAccrueYearly',
      ),
    },
    {
      id: 'monthly-user-tasks',
      name: this._translate.instant('admin.pincoins.automaticAccrual.table.type.user'),
      description: this._translate.instant(
        'admin.pincoins.automaticAccrual.table.description.assingAnAward',
      ),
    },
  ];

  automaticTemplates: DictionaryItem<string>[] = [
    {
      id: 'birthday',
      name: this._translate.instant('admin.templates.automatic.table.type.birthday'),
    },
    {
      id: 'anniversary',
      name: this._translate.instant('admin.templates.automatic.table.type.anniversary'),
    },
    {
      id: 'newcomers',
      name: this._translate.instant('admin.templates.automatic.table.type.newcomers'),
    },
    {
      id: 'probation-end',
      name: this._translate.instant('admin.templates.automatic.table.type.probationEnd'),
    },
  ];

  manualTemplates: DictionaryItem<string>[] = [
    {
      id: 'wedding',
      name: this._translate.instant('admin.templates.manual.table.type.wedding'),
    },
    {
      id: 'birth-child',
      name: this._translate.instant('admin.templates.manual.table.type.birthChild'),
    },
    {
      id: 'promotion',
      name: this._translate.instant('admin.templates.manual.table.type.promotion'),
    },
  ];

  automaticCongratsTypes: DictionaryItem<AutomaticCongratsType>[] = [
    { id: 'birthday', name: this._translate.instant('dictionary.automaticCongratsTypes.birthday') },
    {
      id: 'personalYearStart',
      name: this._translate.instant('dictionary.automaticCongratsTypes.personalYearStart'),
    },
    {
      id: 'probationEnd',
      name: this._translate.instant('dictionary.automaticCongratsTypes.probationEnd'),
    },
  ];

  manualCongratsTypes: DictionaryItem<ManualCongratsType>[] = [
    { id: 'wedding', name: this._translate.instant('dictionary.manualCongratsTypes.wedding') },
    {
      id: 'birthChild',
      name: this._translate.instant('dictionary.manualCongratsTypes.birthChild'),
    },
    { id: 'promotion', name: this._translate.instant('dictionary.manualCongratsTypes.promotion') },
  ];

  templateTypes: DictionaryItem<string>[] = [
    ...this.automaticCongratsTypes,
    ...this.manualCongratsTypes,
    { id: 'newcomers', name: this._translate.instant('dictionary.templateTypes.newcomers') },
  ];

  p2pStatuses: DictionaryItem<string>[] = [
    { id: null, name: this._translate.instant('dictionary.p2pStatuses.all') },
    { id: 'auto', name: this._translate.instant('dictionary.p2pStatuses.auto') },
    { id: 'rejected', name: this._translate.instant('dictionary.p2pStatuses.rejected') },
    { id: 'inReview', name: this._translate.instant('dictionary.p2pStatuses.inReview') },
    { id: 'approved', name: this._translate.instant('dictionary.p2pStatuses.approved') },
  ];

  achievementTypes: DictionaryItem<AchievementType>[] = [
    {
      id: AchievementType.Unique,
      name: this._translate.instant('dictionary.achievementTypes.unique'),
    },
    {
      id: AchievementType.Colleagues,
      name: this._translate.instant('dictionary.achievementTypes.colleagues'),
    },
    {
      id: AchievementType.Managers,
      name: this._translate.instant('dictionary.achievementTypes.managers'),
    },
    {
      id: AchievementType.Education,
      name: this._translate.instant('dictionary.achievementTypes.education'),
    },
    {
      id: AchievementType.Onboarding,
      name: this._translate.instant('dictionary.achievementTypes.onboarding'),
    },
  ];

  achievementStatuses: DictionaryItem<AchievementStatus>[] = [
    {
      id: AchievementStatus.Sent,
      name: this._translate.instant('dictionary.achievementStatuses.sent'),
    },
    {
      id: AchievementStatus.Received,
      name: this._translate.instant('dictionary.achievementStatuses.received'),
    },
  ];

  tgChatPermissions: DictionaryItem<TgChatPermissions>[] = [
    {
      id: TgChatPermissions.Member,
      name: this._translate.instant('dictionary.tgChatPermissions.member'),
    },
    {
      id: TgChatPermissions.Admin,
      name: this._translate.instant('dictionary.tgChatPermissions.admin'),
    },
  ];

  tgUserPermissions: DictionaryItem<TgUserPermissions>[] = [
    {
      id: TgUserPermissions.Member,
      name: this._translate.instant('dictionary.tgUserPermissions.member'),
    },
    {
      id: TgUserPermissions.Owner,
      name: this._translate.instant('dictionary.tgUserPermissions.owner'),
    },
    {
      id: TgUserPermissions.Admin,
      name: this._translate.instant('dictionary.tgUserPermissions.admin'),
    },
  ];

  tgChatTypes: DictionaryItem<TgChatTypes>[] = [
    {
      id: TgChatTypes.Channel,
      name: this._translate.instant('dictionary.tgChatTypes.channel'),
    },
    {
      id: TgChatTypes.Bot,
      name: this._translate.instant('dictionary.tgChatTypes.bot'),
    },
    {
      id: TgChatTypes.User,
      name: this._translate.instant('dictionary.tgChatTypes.user'),
    },
  ];

  userStatuses: DictionaryItem<UserStatusType>[] = [
    { id: 'active', name: this._translate.instant('dictionary.userStatuses.active') },
    { id: 'probation', name: this._translate.instant('dictionary.userStatuses.probation') },
    { id: 'offboarding', name: this._translate.instant('dictionary.userStatuses.offboarding') },
  ];

  channelTypes: DictionaryItem<SlackChannelType>[] = [
    { id: 'news', name: this._translate.instant('dictionary.channelTypes.news') },
    { id: 'team', name: this._translate.instant('dictionary.channelTypes.team') },
    { id: 'club', name: this._translate.instant('dictionary.channelTypes.club') },
    { id: 'office', name: this._translate.instant('dictionary.channelTypes.office') },
  ];

  sortTypes: SortType[] = [
    { id: 'id', name: this._translate.instant('dictionary.sortTypes.id') },
    { id: '-id', name: this._translate.instant('dictionary.sortTypes.idDesc') },
    { id: 'name', name: this._translate.instant('dictionary.sortTypes.name') },
    { id: '-name', name: this._translate.instant('dictionary.sortTypes.nameDesc') },
    { id: 'login', name: this._translate.instant('dictionary.sortTypes.login') },
    { id: '-login', name: this._translate.instant('dictionary.sortTypes.loginDesc') },
    { id: 'date', name: this._translate.instant('dictionary.sortTypes.date') },
    { id: '-date', name: this._translate.instant('dictionary.sortTypes.dateDesc') },
    { id: 'title', name: this._translate.instant('dictionary.sortTypes.title') },
    { id: '-title', name: this._translate.instant('dictionary.sortTypes.titleDesc') },
    { id: 'price', name: this._translate.instant('dictionary.sortTypes.price') },
    { id: '-price', name: this._translate.instant('dictionary.sortTypes.priceDesc') },
    { id: 'reward', name: this._translate.instant('dictionary.sortTypes.reward') },
    { id: '-reward', name: this._translate.instant('dictionary.sortTypes.rewardDesc') },
    { id: 'members', name: this._translate.instant('dictionary.sortTypes.members') },
    { id: '-members', name: this._translate.instant('dictionary.sortTypes.membersDesc') },
    { id: 'new', name: this._translate.instant('dictionary.sortTypes.new') },
    { id: 'sale', name: this._translate.instant('dictionary.sortTypes.sale') },
    { id: 'mostRecently', name: this._translate.instant('dictionary.sortTypes.mostRecently') },
  ];

  workingMessengers: DictionaryItem<string>[] = [
    { id: Messengers.Telegram, name: 'Telegram' },
    { id: Messengers.Instagram, name: 'Instagram' },
    { id: Messengers.WhatsApp, name: 'WhatsApp' },
    { id: Messengers.LinkedIn, name: 'LinkedIn' },
    { id: Messengers.FaceBook, name: 'Facebook' },
  ];

  workingMessengerLinks: WorkingMessengerLinks = {
    [Messengers.Telegram]: {
      prefix: 'https://www.telegram.me/',
    },
    [Messengers.Instagram]: {
      prefix: 'https://www.instagram.com/',
    },
    [Messengers.WhatsApp]: {
      prefix: 'https://wa.me/',
    },
    [Messengers.FaceBook]: {
      prefix: '',
      substringPrefix: 'https://www.facebook.com/',
    },
    [Messengers.LinkedIn]: {
      prefix: '',
      substringPrefix: 'https://www.linkedin.com/in/',
    },
  };

  groupTags: DictionaryItem<GroupTagEnum>[] = [
    { id: GroupTagEnum.Courses, name: this._translate.instant('dictionary.groupTags.courses') },
    {
      id: GroupTagEnum.CoursesNotification,
      name: this._translate.instant('dictionary.groupTags.coursesNotification'),
    },
    { id: GroupTagEnum.FAQ, name: this._translate.instant('dictionary.groupTags.faq') },
    { id: GroupTagEnum.Messages, name: this._translate.instant('dictionary.groupTags.messages') },
    { id: GroupTagEnum.News, name: this._translate.instant('dictionary.groupTags.news') },
    { id: GroupTagEnum.Pincoins, name: this._translate.instant('dictionary.groupTags.pincoins') },
    { id: GroupTagEnum.Survey, name: this._translate.instant('dictionary.groupTags.survey') },
  ];

  usersCourseStatuses: DictionaryItem<CourseStatus>[] = [
    {
      id: CourseStatus.NotPassed,
      name: this._translate.instant('dictionary.usersCourseStatuses.notPassed'),
    },
    {
      id: CourseStatus.InProgress,
      name: this._translate.instant('dictionary.usersCourseStatuses.inProgress'),
    },
    {
      id: CourseStatus.Completed,
      name: this._translate.instant('dictionary.usersCourseStatuses.completed'),
    },
    {
      id: CourseStatus.NotStarted,
      name: this._translate.instant('dictionary.usersCourseStatuses.notStarted'),
    },
  ];

  constructor(private _translate: TranslateService) {
  }

  filterSortTypes(...ids: string[]): SortType[] {
    const doubleIds = [...ids, ...ids.map(id => `-${id}`)];

    return this.sortTypes.filter(item => doubleIds.includes(item.id));
  }
}
