export const accessDataMock = {
  permissions: [
    'Admin',
    'AdminUsers',
    'AdminUsersGroups',
    'AdminUsersCabinetIds',
    'AdminUsersLogins',
    'AdminUsersStatuses',
    'AdminUsersBalance',
    'AdminUsersLocations',
    'AdminUsersFunctionalBalance',
    'AdminUsersAchievementsHistory',
    'AdminUsers2FAForUsers',
    'AdminAdmins',
    'AdminUserInfo',
    'AdminTransactionsFunctional',
    'AdminMessageHubLearningAndDevelopmentMessages',
    'AdminMessageHubItsMessages',
    'AdminPinCoins',
    'AdminPinCoinsAutomaticAccrual',
    'AdminPinCoinsEventRewards',
    'AdminProducts',
    'AdminProductsEditable',
    'AdminLocations',
    'AdminOrders',
    'AdminNews',
    'AdminFAQ',
    'AdminP2P&Limits',
    'AdminQuests',
    'AdminQuestCatalog',
    'AdminSkins',
    'AdminSupport',
    'AdminSupportOffice',
    'AdminSupportPlatform',
    'AdminSupportEducation',
    'AdminSupportFinance',
    'AdminAchievements',
    'AdminCourses',
    'AdminCoursesAward',
    'AdminCalendar',
    'AdminChannels',
    'AdminTemplates',
    'AdminCongrats',
    'AdminOnboarding',
    'AdminPpWards',
    'AdminClubs',
    'AdminAnalytics',
    'AdminTgChats',
    'LMSAnalytic',
    'user',
  ],
};
